'use client';

import { useContext } from 'react';

import { CurrencyContext } from 'common-toolbox/src/context/CurrencyContext';
import { CurrencyList } from 'common-toolbox/src/types/currency';

import SelectMenuItem from './SelectMenuItem';

const OPTIONS = CurrencyList.map(({ symbol_native, code }) => ({
  value: code,
  label: `${code} - (${symbol_native})`,
}));

function CurrencyMenuItem() {
  const { state, actions } = useContext(CurrencyContext);
  const onCurrencyChange = (newValue: string) => {
    actions.setCurrency(newValue);
  };
  return (
    <SelectMenuItem
      selectedItem={state.currency?.code}
      options={OPTIONS}
      onSelect={onCurrencyChange}
      label="Currency"
    />
  );
}

export default CurrencyMenuItem;
