'use client';

import { useEffect } from 'react';

function useEscapeButton(onEscape: () => void) {
  useEffect(() => {
    function handleClickOutside(e: any) {
      if (e.key === 'Escape') {
        onEscape();
      }
    }

    document.addEventListener('keydown', handleClickOutside);

    return () => {
      document.removeEventListener('keydown', handleClickOutside);
    };
  });
}

export default useEscapeButton;
