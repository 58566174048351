'use client';

import { usePathname } from 'next/navigation';

import { signOut } from '../../auth/signOut';
import { useSession } from '../../auth/useSession';
import ChatMenuItem from './ChatMenuItem';
import CurrencyMenuItem from './CurrencyMenuItem';
import MenuDivider from './MenuDivider';
import MenuItem from './MenuItem';
import MenuLabel from './MenuLabel';

const ADMIN_URL = `${process.env.NEXT_PUBLIC_USER_ENVIRONMENT_HOST}/admin/`;
const BUY_A_GIFT_VOUCHER_LINK =
  'https://giftup.app/place-order/9eeeca52-42dc-4e48-f9c3-08dab8104ad7?platform=hosted&_gl=1*147f5ks*_ga*NTQ5OTI3MzI0LjE2NjAyOTQ0ODU.*_ga_8V4J1MY5HV*MTY3MzQ0NTYxNy4zMzMuMS4xNjczNDQ1NzE1LjU4LjAuMA..*_fplc*RXVLd3ZLd3JVZmluV25JeSUyQlVjWEs4cTlKcU5tdGd1NHk1Q29qbk5OWiUyQlZLOE42WnRGOWpKZ3pNbjRuMnFnOEZmJTJGVkc5b2tmdlIyZW9JYk1RQXYlMkYxVWZsWUs4UDBhJTJGOVpUU2VVYXRGUTlNYjJZaVYzVkE2WiUyRmxnM1pHNnJ3JTNEJTNE';

interface NavigationMenuListProps {
  roles: string[];
  onLinkClick: () => void;
}

function NavigationMenuList({ roles, onLinkClick }: NavigationMenuListProps) {
  const pathname = usePathname()!;
  const { refetch } = useSession();

  return (
    <>
      {!roles.includes('host') && (
        <MenuItem
          text="Home"
          url={process.env.NEXT_PUBLIC_STOREFRONT_HOST}
          onClick={onLinkClick}
          isActive={pathname === '/'}
        />
      )}
      {roles.includes('host') && (
        <MenuItem
          text="Dashboard"
          url={`${process.env.NEXT_PUBLIC_USER_ENVIRONMENT_HOST}/host/home/`}
          onClick={onLinkClick}
          isActive={pathname === '/host/home/'}
        />
      )}
      {roles.includes('onboarding') && (
        <MenuItem
          text="Onboarding"
          url={`${process.env.NEXT_PUBLIC_USER_ENVIRONMENT_HOST}/host/onboarding/`}
          onClick={onLinkClick}
          isActive={pathname === '/host/onboarding/'}
        />
      )}
      <MenuItem
        text="Search"
        url={`${process.env.NEXT_PUBLIC_STOREFRONT_HOST}/search/`}
        onClick={onLinkClick}
        isActive={pathname === '/search/'}
        openModal
      />
      {roles.includes('unlogged') && (
        <>
          <MenuDivider />
          <MenuLabel>Profile</MenuLabel>
          <MenuItem
            text="Log in"
            url={`${process.env.NEXT_PUBLIC_STOREFRONT_HOST}/login/`}
            onClick={onLinkClick}
            openModal
          />
          <MenuItem
            text="Sign up"
            url={`${process.env.NEXT_PUBLIC_STOREFRONT_HOST}/signup/`}
            onClick={onLinkClick}
            openModal
          />
        </>
      )}
      {roles.includes('loggedin') && (
        <>
          <MenuDivider />
          <MenuLabel>Manage</MenuLabel>
          <ChatMenuItem onLinkClick={onLinkClick} />
          <MenuItem
            text="Bookings"
            url={`${process.env.NEXT_PUBLIC_USER_ENVIRONMENT_HOST}/schedule/`}
            onClick={onLinkClick}
            isActive={pathname.startsWith('/schedule/')}
          />
          {roles.includes('host') && (
            <MenuItem
              text="Updates"
              url={`${process.env.NEXT_PUBLIC_USER_ENVIRONMENT_HOST}/host/updates-center/`}
              onClick={onLinkClick}
              isActive={pathname === '/host/updates-center/'}
            />
          )}
          {roles.includes('guest') && (
            <MenuItem
              text="Favorites"
              url={`${process.env.NEXT_PUBLIC_STOREFRONT_HOST}/favorites/`}
              onClick={onLinkClick}
              isActive={pathname === '/favorites/'}
            />
          )}
        </>
      )}
      {roles.includes('host') && (
        <>
          <MenuDivider />
          <MenuLabel>Manage</MenuLabel>
          <MenuItem
            text="Agenda"
            url={`${process.env.NEXT_PUBLIC_USER_ENVIRONMENT_HOST}/host-agenda/`}
            onClick={onLinkClick}
            isActive={pathname.startsWith('/host-agenda/')}
          />
          <MenuItem
            text="Finance information"
            url={`${process.env.NEXT_PUBLIC_HOST_FINANCES_HOST}`}
            onClick={onLinkClick}
            isActive={pathname.includes('/host/finance')}
          />
          <MenuItem
            text="Experiences"
            url={`${process.env.NEXT_PUBLIC_USER_ENVIRONMENT_HOST}/host/experiences/`}
            onClick={onLinkClick}
            isActive={pathname === '/host/experiences/'}
          />
        </>
      )}
      {(roles.includes('admin') || roles.includes('impersonator')) && (
        <>
          <MenuDivider />
          <MenuLabel>Admin actions</MenuLabel>
          <MenuItem
            text="Admin / Kebab"
            url={ADMIN_URL}
            onClick={onLinkClick}
          />
          {roles.includes('impersonator') && (
            <MenuItem
              text="Become yourself again"
              url={`${process.env.NEXT_PUBLIC_AUTHENTICATION_HOST}/admin/unspy/?callbackUrl=${window.location.href}`}
              onClick={onLinkClick}
            />
          )}

          <MenuItem
            text="Impersonate someone"
            url={`${process.env.NEXT_PUBLIC_AUTHENTICATION_HOST}/admin/impersonate`}
            onClick={onLinkClick}
          />
          <MenuItem
            text="Feature Flags"
            url={`${process.env.NEXT_PUBLIC_USER_ENVIRONMENT_HOST}/feature-flags/`}
            onClick={onLinkClick}
            isActive={pathname === '/feature-flags/'}
          />
          <MenuItem
            text="AB tests"
            url={`${process.env.NEXT_PUBLIC_USER_ENVIRONMENT_HOST}/ab-tests/`}
            onClick={onLinkClick}
            isActive={pathname === '/ab-tests/'}
          />
        </>
      )}
      {roles.includes('loggedin') && (
        <>
          <MenuDivider />
          <MenuLabel>Profile</MenuLabel>
          {(roles.includes('host') || roles.includes('onboarding')) && (
            <MenuItem
              text="Edit profile"
              url={`${process.env.NEXT_PUBLIC_USER_ENVIRONMENT_HOST}/host/profile/`}
              onClick={onLinkClick}
              isActive={pathname === '/host/profile/'}
            />
          )}
          {roles.includes('guest') && (
            <MenuItem
              text="Edit profile"
              url={`${process.env.NEXT_PUBLIC_USER_ENVIRONMENT_HOST}/account/`}
              onClick={onLinkClick}
            />
          )}
          <MenuItem
            text="Change password"
            url={`${process.env.NEXT_PUBLIC_STOREFRONT_HOST}/password/`}
            onClick={onLinkClick}
          />
        </>
      )}
      <MenuDivider />
      <MenuLabel>Support</MenuLabel>
      <MenuItem
        text="Buy a gift voucher"
        url={BUY_A_GIFT_VOUCHER_LINK}
        onClick={onLinkClick}
        openInNewTab
      />
      {!roles.includes('host') && (
        <MenuItem
          text="Become a host"
          url={`${process.env.NEXT_PUBLIC_USER_ENVIRONMENT_HOST}/become-a-host/`}
          onClick={onLinkClick}
        />
      )}
      {roles.includes('host') && (
        <MenuItem
          text="Learning center"
          url={`${process.env.NEXT_PUBLIC_USER_ENVIRONMENT_HOST}/learning-center/`}
          onClick={onLinkClick}
          isActive={pathname === '/learning-center/'}
        />
      )}
      <MenuItem
        text="Consent"
        url={`${process.env.NEXT_PUBLIC_STOREFRONT_HOST}/consent/`}
        onClick={onLinkClick}
        isActive={pathname === '/consent/'}
      />
      <MenuItem
        text="Privacy policy"
        url={`${process.env.NEXT_PUBLIC_USER_ENVIRONMENT_HOST}/info/privacy-policy/`}
        onClick={onLinkClick}
        isActive={pathname === '/info/privacy-policy/'}
      />
      <MenuItem
        text="Help"
        url="https://support.withlocals.com/hc/en-us"
        onClick={onLinkClick}
        openInNewTab
      />
      <MenuDivider />
      <CurrencyMenuItem />
      {roles.includes('loggedin') && (
        <>
          <MenuDivider />
          <MenuItem
            text="Log out"
            onClick={async () => {
              await signOut(window.location.href);
              await refetch();
              onLinkClick();
            }}
          />
        </>
      )}
    </>
  );
}

export default NavigationMenuList;
