'use client';

import { useRef, useState } from 'react';
import { useClickAway } from 'react-use';

import NavbarMenu from 'common-toolbox/src/components/NavigationMenu/NavbarMenu';
import { Account, RoleType } from 'common-toolbox/src/types/model/account';

import MenuToggle from './MenuToggle';
import CSS from './NavigationMenu.module.scss';

interface NavigationMenuProps {
  roles: RoleType[];
  account: Account | null;
}

function NavigationMenu({ roles, account }: NavigationMenuProps) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const onToggleMenu = () => setIsMenuOpen(!isMenuOpen);
  const onLinkClick = () => setIsMenuOpen(false);
  const menuRef = useRef<HTMLDivElement>(null);

  useClickAway(menuRef, () => setIsMenuOpen(false));

  return (
    <div className={CSS.NavigationMenu}>
      <MenuToggle
        roles={roles}
        onToggleMenu={onToggleMenu}
        isMenuOpen={isMenuOpen}
        cloudinaryId={account?.cloudinaryId}
        status={account?.hostPublicStatus}
      />
      {isMenuOpen && <NavbarMenu onClose={onLinkClick} roles={roles} />}
    </div>
  );
}

export default NavigationMenu;
