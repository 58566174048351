const AUTHENTICATION_HOST = process.env.NEXT_PUBLIC_AUTHENTICATION_HOST;

function findCloudinaryId(src: string = '') {
  const urlLastPartSplitOnUpload = src.split('/upload/').pop();
  if (urlLastPartSplitOnUpload?.includes('q_auto')) {
    return urlLastPartSplitOnUpload.split('/').slice(1)?.join('/');
  }
  return urlLastPartSplitOnUpload;
}

export async function loginWithMainSessionToken(mainSessionToken: string) {
  const response = await fetch(
    `${process.env.NEXT_PUBLIC_MAIN_API_HOST}/api/v1/account/me`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        WithlocalsAuthenticator: mainSessionToken,
      },
    },
  );

  const account = await response.json();
  if (response.ok && account) {
    return {
      id: account.id,
      email: account.email,
      name: account.fullName,
      image: account.photo,
      roles: [account?.is_host && 'host', account?.is_admin && 'admin'].filter(
        Boolean,
      ),
      isHost: account?.is_host ?? false,
      isAdmin: account?.is_admin ?? false,
      cloudinaryId: findCloudinaryId(account?.photo),
    };
  }

  return null;
}

export async function requestPasswordResetEmail(email: string) {
  const response = await fetch(
    `${AUTHENTICATION_HOST}/api/v1/auth/password/reset`,
    {
      method: 'POST',
      body: JSON.stringify({ email }),
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include',
    },
  );

  const data = await response.json();

  return { success: response.ok, data };
}

export async function resetPassword(
  email: string,
  password: string,
  jwt: string,
) {
  const response = await fetch(
    `${AUTHENTICATION_HOST}/api/v1/auth/password/reset`,
    {
      method: 'PUT',
      body: JSON.stringify({ email, newPassword: password, token: jwt }),
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include',
    },
  );

  const data = await response.json();

  return { success: response.ok, data };
}

export async function changePassword(
  currentPassword: string,
  newPassword: string,
) {
  const response = await fetch(
    `${process.env.NEXT_PUBLIC_AUTHENTICATION_HOST}/api/v1/auth/password`,
    {
      method: 'put',
      body: JSON.stringify({ currentPassword, newPassword }),
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include',
    },
  );

  try {
    const data = await response.json();

    if (response.ok) {
      return { success: true, data };
    }

    return { success: false, error: data.error };
  } catch {
    return {
      success: false,
      error: { message: 'Server error. Please try again later.' },
    };
  }
}

export async function checkCandidateProgress(userId: string, token: string) {
  const response = await fetch(
    `${process.env.NEXT_PUBLIC_MAIN_API_HOST}/api/v1/portfolio/host/candidate/${userId}/progress`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    },
  );

  return { isCandidate: response.ok };
}

export async function getAccountData(token: string) {
  const response = await fetch(
    `${process.env.NEXT_PUBLIC_MAIN_API_HOST}/api/v1/account`,
    {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      cache: 'no-cache',
    },
  );

  const data = await response.json();

  return { success: response.ok, data };
}

export async function resetAccount(token: string, mainSession: string) {
  const response = await fetch(
    `${process.env.NEXT_PUBLIC_MAIN_API_HOST}/api/v1/account/reset`,
    {
      method: 'POST',
      body: JSON.stringify({}),
      headers: {
        'Content-Type': 'application/json',
        WithlocalsAuthenticator: token,
        cookie: `PLAY_SESSION=${mainSession}`,
      },
    },
  );

  return { success: response.ok };
}

export async function createAccountInMain({
  email,
  firstName,
  lastName,
  phoneNumber,
}: {
  email: string;
  firstName: string;
  lastName: string;
  phoneNumber: string | null;
}) {
  // Create account and corresponding account_profile rows etc via the main API
  const createAccountResponse = await fetch(
    `${process.env.NEXT_PUBLIC_MAIN_API_HOST}/api/v1/account/internal/account-basic-auth`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization:
          'Basic ' +
          Buffer.from(process.env.MAIN_API_BASIC_AUTH as string).toString(
            'base64',
          ),
      },
      body: JSON.stringify({
        email,
        firstName,
        lastName,
        phoneNr: phoneNumber,
      }),
    },
  );
  const createAccountJson: { created: boolean; id: string; account: any } =
    await createAccountResponse.json();
  return createAccountJson;
}
