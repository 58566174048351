import { getCsrfToken } from './signIn';

const AUTHENTICATION_HOST = process.env.NEXT_PUBLIC_AUTHENTICATION_HOST;

export const signOut = async (callbackUrl: string) => {
  try {
    const csrfToken = await getCsrfToken();

    if (!csrfToken) {
      return {
        success: false,
        error: 'No CSRF token',
        redirectUrl: null,
      };
    }

    const params = new URLSearchParams();
    params.set('callbackUrl', callbackUrl);
    params.set('csrfToken', csrfToken);
    params.set('json', 'true');

    const signoutResponse = await fetch(
      `${AUTHENTICATION_HOST}/api/auth/signout`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        credentials: 'include',
        body: params.toString(),
      },
    );

    void (await signoutResponse.json());

    try {
      window.localStorage.removeItem('auth-storage');
    } catch (error) {
      console.error('Failed to remove auth-storage from localStorage', error);
    }

    return {
      success: true,
      error: null,
      redirectUrl: callbackUrl || '/',
    };
  } catch (error) {
    console.error('Error signing out', error);
    return {
      success: false,
      error: error,
      redirectUrl: '/',
    };
  }
};
