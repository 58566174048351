import classnames from 'classnames';
import React from 'react';

import SearchGlassIcon from 'common-toolbox/src/components/icons/tools-items/outlined/SearchGlassIcon';
import Link from 'common-toolbox/src/components/links/Link';

import CSS from './NavbarSearchTrigger.module.scss';

interface NavbarSearchTriggerProps {
  isFloating?: boolean;
}

function NavbarSearchTrigger({ isFloating }: NavbarSearchTriggerProps) {
  return (
    <div className={CSS.NavbarSearchTrigger}>
      <Link
        href={`${process.env.NEXT_PUBLIC_STOREFRONT_HOST}/search/`}
        className={classnames(CSS.SearchToggle, {
          [CSS.FloatingSearchToggle]: isFloating,
        })}
      >
        <span className={CSS.PlaceHolder}>Where are you going?</span>
        <span className={CSS.IconContainer}>
          <SearchGlassIcon size={20} variant="black" className={CSS.Icon} />
        </span>
      </Link>
    </div>
  );
}

export default NavbarSearchTrigger;
