'use client';

import { Account, RoleType } from 'common-toolbox/src/types/model/account';

import ChevronIcon from '../icons/symbols/full-color/ChevronIcon';
import HamburgerCrossIcon from './HamburgerCrossIcon';
import CSS from './MenuToggle.module.scss';
import RoleBadge from './RoleBadge';

interface MenuToggleProps {
  isMenuOpen: boolean;
  onToggleMenu: () => void;
  roles: RoleType[];
  cloudinaryId?: string;
  status?: Account['hostPublicStatus'];
}

function MenuToggle({
  isMenuOpen,
  onToggleMenu,
  roles,
  cloudinaryId,
  status,
}: MenuToggleProps) {
  const isLoggedIn = roles.includes('loggedin');

  if (isLoggedIn) {
    return (
      <div className={CSS.AvatarToggle} data-open={String(isMenuOpen)}>
        <div className={CSS.AvatarContainer}>
          <img
            alt={'Avatar'}
            src={`https://withlocals-com-res.cloudinary.com/image/upload/w_38,h_38,c_thumb,z_0.6,g_faces,q_auto,dpr_2.0,f_auto/${cloudinaryId}`}
            className={CSS.Avatar}
          />
          {status && <div className={CSS.AvatarStatus} data-status={status} />}
        </div>
        <button
          onClick={onToggleMenu}
          className={CSS.Button}
          data-open={String(isMenuOpen)}
          aria-label={isMenuOpen ? 'Close menu' : 'Open menu'}
        >
          <ChevronIcon
            className={CSS.Icon}
            size={12}
            direction={isMenuOpen ? 'up' : 'down'}
          />
        </button>
        <RoleBadge roles={roles} />
      </div>
    );
  }

  return (
    <button
      onClick={onToggleMenu}
      className={CSS.ToggleButton}
      data-open={String(isMenuOpen)}
      aria-label={isMenuOpen ? 'Close menu' : 'Open menu'}
    >
      <HamburgerCrossIcon isOpen={isMenuOpen} />
    </button>
  );
}

export default MenuToggle;
