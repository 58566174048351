import IconType from '../../IconType';
import SVGIcon from '../../SVGIcon';
import CSS from './SpyIcon.module.scss';

type SpyIconProps = {
  variant?: 'default' | 'green';
} & IconType;

function SpyIcon({ variant, ...props }: SpyIconProps) {
  return (
    <SVGIcon {...props} size={props.size ?? 24} viewBox="0 0 24 24">
      <path
        d="M11.8380558,3.91 C7.76232228,3.91 4.44655107,7.22577121 4.44655107,11.3024465 C4.44655107,15.37818 7.76232228,18.6930095 11.8380558,18.6930095 C15.9137894,18.6930095 19.2305023,15.37818 19.2305023,11.3024465 C19.2305023,7.22577121 15.9137894,3.91 11.8380558,3.91"
        data-variant={variant}
        className={CSS.Face}
      />
      <path
        d="M17.4478397,6.48596154 C16.1129002,4.95105136 14.136065,4.00289419 12.1156625,3.91675413 L11.8107462,3.91037669 C9.62973901,3.91037669 7.49110896,4.90670896 6.08324813,6.58107454 C5.03780982,7.82298069 4.47281899,9.3296789 4.43231569,10.93073 C4.41965677,11.4324056 9.29637264,10.1153232 9.82655702,7.3137272 C12.4200344,10.0390444 19.2072492,11.4174799 19.1903977,10.9533163 C19.1143521,8.86823087 18.2458158,7.40178546 17.4478397,6.48596154 Z"
        data-variant={variant}
        className={CSS.Hair}
      />
      <path
        d="M9.37497289,10.8968182 C9.78952322,10.8968182 10.182062,11.0035142 10.5525892,11.2169064 C10.9231251,11.4302985 11.2237273,11.7308228 11.454396,12.1184793 L12.4074736,12.1184793 C12.6327445,11.7285874 12.9314883,11.4275043 13.303705,11.2152298 C13.6759217,11.0029554 14.070319,10.8968182 14.4868968,10.8968182 L19.2194687,10.8960745 C19.2267926,11.030617 19.2305023,11.1661062 19.2305023,11.3024465 C19.2305023,11.6260975 19.2095877,11.9449505 19.1690391,12.2577264 L17.3937483,12.2570988 L17.1294927,14.3928483 C17.0486291,15.0311186 16.7793135,15.5567491 16.3215458,15.9697399 C15.8637868,16.3827393 15.3320826,16.589239 14.7264333,16.589239 C14.2143927,16.589239 13.7511535,16.4325588 13.3367158,16.1191984 C12.9222781,15.8058467 12.6226376,15.3921281 12.4377942,14.8780427 L12.1692107,14.1112554 C12.1576526,14.0881565 12.1489883,14.0621637 12.1432179,14.0332771 C12.1374389,14.0043991 12.1339082,13.9784063 12.1326259,13.9552987 L11.7013275,13.9552987 L11.6912163,14.0337579 C11.6864077,14.062324 11.6782243,14.0881565 11.6666661,14.1112554 L11.4240754,14.8650463 C11.2478963,15.387796 10.9468868,15.8058467 10.521047,16.1191984 C10.0951984,16.4325588 9.62552172,16.589239 9.11201684,16.589239 C8.48732347,16.589239 7.94753988,16.3827393 7.49266607,15.9697399 C7.03779226,15.5567491 6.77425571,15.0311186 6.7020564,14.3928483 L6.44212851,12.2570988 L4.50799738,12.2577264 C4.46745977,11.9449505 4.44655107,11.6260975 4.44655107,11.3024465 C4.44655107,11.1661062 4.45025976,11.030617 4.45758153,10.8960745 L9.37497289,10.8968182 Z"
        data-variant={variant}
        className={CSS.Sunglasses}
      />
      <path
        d="M16.5190416,22.2347918 C16.0179767,21.1914287 17.1726042,19.9395726 16.0643207,19.3990559 C15.1729879,18.9643473 13.0935684,19 12.0042643,19 C10.5935597,18.9999776 8.68110477,18.6931138 7.64522502,19.3990559 C6.88804682,19.9150656 7.29357879,21.4398505 6.91181605,22.2347918 C6.84118758,22.3807573 16.58967,22.3807573 16.5190416,22.2347918 Z M17.8779216,5.2509125 C17.9576316,5.73225897 18,6.17001202 18,6.50934889 L6,6.50934889 C6,6.15980743 6.0572511,5.72392116 6.16075263,5.25050873 L17.8779216,5.2509125 Z"
        data-variant={variant}
        className={CSS.HatbandAndShirt}
      />
      <path
        d="M12.5,19 C12.7761424,19 13,19.2238576 13,19.5 C13,19.7416246 12.7096287,20.117913 12.5561498,20.3903131 L12.5,20.5 C12.4055645,20.7126081 12.3324098,20.7801587 12.1934136,20.8197573 C12.3399616,21.1811587 12.427871,22.0121795 12.5230663,22.2264981 C12.6438058,22.4983259 13,22.4781851 13,22.7543275 L11,22.7543275 C11,22.5127029 11.0887125,22.7341699 11.2421913,22.4617698 L11.4438502,22.2264981 C11.5391276,22.0119946 11.6477078,21.1797242 11.8038815,20.8174348 C11.6665871,20.7792265 11.5937887,20.7111519 11.5,20.5 C11.3792605,20.2281723 11,19.7761424 11,19.5 C11,19.2238576 11.2238576,19 11.5,19 L12.5,19 Z"
        data-variant={variant}
        className={CSS.Tie}
      />
      <path
        d="M16.0130447,1.00934889 C16.9270449,1.54344528 17.6113829,3.64117978 17.8779569,5.25112558 L6.16069334,5.25077994 C6.51912772,3.61108321 7.43245544,1.52107195 8.4435562,1.00934889 C8.98553347,0.735051531 9.5610202,0.735051531 10,1.00934889 C10.8818498,1.7042573 11.5485164,2.0517115 12,2.0517115 C12.4374053,2.0517115 13.104072,1.7042573 14,1.00934889 C14.5043215,0.66355037 15.421279,0.66355037 16.0130447,1.00934889 Z M20.5,6.50934889 C21.0522847,6.50934889 21.5,6.95706414 21.5,7.50934889 C21.5,8.06163364 21.0522847,8.50934889 20.5,8.50934889 L3.5,8.50934889 C2.94771525,8.50934889 2.5,8.06163364 2.5,7.50934889 C2.5,6.95706414 2.94771525,6.50934889 3.5,6.50934889 L20.5,6.50934889 Z M14.3819617,18.8860908 L14.5217877,18.867029 C16.3399328,18.7007555 16.7922123,17.3449972 17.3961062,17.5264948 C18,17.7079925 16.7922123,18.4433311 17.3961062,19.47867 C17.7987021,20.1688959 18,20.6760059 18,21 C18,22.1045695 17.2251456,23 16.1205761,23 L12,23 L12,22.4370178 C12.507584,22.4370178 13.5879347,21.414636 13.5879347,20.7167294 L13.5916108,20.2624185 C13.609991,19.5404302 13.7202726,19.0089628 14.3819617,18.8860908 Z M9.61800617,18.8860908 L9.4781801,18.867029 C7.66003507,18.7007555 7.20775553,17.3449972 6.60386168,17.5264948 C5.99996783,17.7079925 7.20775553,18.4433311 6.60386168,19.47867 C6.20126578,20.1688959 5.99996783,20.6760059 5.99996783,21 C5.99996783,22.1045695 6.77482222,23 7.87939172,23 L12,23 L12,22.4370178 C11.492416,22.4370178 10.4120331,21.414636 10.4120331,20.7167294 L10.408357,20.2624185 C10.3899768,19.5404302 10.2796953,19.0089628 9.61800617,18.8860908 Z"
        data-variant={variant}
        className={CSS.HatAndJacket}
      />
    </SVGIcon>
  );
}

export default SpyIcon;
