import classnames from 'classnames';
import React, { ReactNode } from 'react';
import ReactDOM from 'react-dom';

import useEscapeButton from 'common-toolbox/src/hooks/useEscapeButton';

import CSS from './Overlay.module.scss';

interface OverlayProps {
  portalId: string;
  onEsc: () => void;
  className?: any;
  onClick?: (event?: any) => void;
  children: ReactNode;
  fullscreen?: 'all' | 'mobile';
  containerSize?: 'small' | 'medium' | 'large';
}

function Overlay({
  onClick,
  children,
  onEsc,
  fullscreen,
  containerSize = 'large',
}: OverlayProps) {
  useEscapeButton(onEsc);

  const classes = classnames(CSS.Overlay, {
    [CSS.SmallContainerOverlay]: containerSize === 'small',
    [CSS.MediumContainerOverlay]: containerSize === 'medium',
    [CSS.OverlayFullscreen]: fullscreen === 'all',
    [CSS.OverlayFullscreenMobile]: fullscreen === 'mobile',
  });

  return (
    <>
      {ReactDOM.createPortal(
        <div onClick={onClick} className={classes}>
          {children}
        </div>,
        document.getElementById('overlay-container') || document.body,
      )}
    </>
  );
}

export default Overlay;
