import errorMessages from '../components/authentication/ErrorMessages';

const AUTHENTICATION_HOST = process.env.NEXT_PUBLIC_AUTHENTICATION_HOST;
export const getCsrfToken = async (): Promise<string | null> => {
  try {
    const csrfResponse = await fetch(`${AUTHENTICATION_HOST}/api/auth/csrf`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
      },
      credentials: 'include',
    });
    const csrf = await csrfResponse.json();
    return csrf.csrfToken;
  } catch (exception) {
    console.error('Error getting CSRF token', exception);
    return null;
  }
};

export type SignInResponse = {
  error: any;
  success: boolean;
  redirectUrl: string | null;
};

export const signIn = async (provider: string, callbackUrl = '/') => {
  try {
    const csrfToken = await getCsrfToken();

    if (!csrfToken) {
      return { success: false, error: 'No CSRF token', redirectUrl: null };
    }

    const params = new URLSearchParams();
    params.set('callbackUrl', callbackUrl ?? '/');
    params.set('csrfToken', csrfToken);
    params.set('json', 'true');

    const signinResponse = await fetch(
      `${AUTHENTICATION_HOST}/api/auth/signin/${provider}`,
      {
        method: 'POST',
        headers: {
          Accept: '*/*',
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        credentials: 'include',
        body: params.toString(),
      },
    );

    const signin = await signinResponse.json();

    const { url } = signin;
    return { success: true, error: null, redirectUrl: url };
  } catch (error) {
    console.error('[signIn] Error signing in', error, provider, callbackUrl);
    return { success: false, error, redirectUrl: null };
  }
};

export const signInWithCredentials = async (
  username: string,
  password: string,
  callbackUrl: string,
) => {
  try {
    const csrfToken = await getCsrfToken();

    if (!csrfToken) {
      return {
        success: false,
        error: 'No CSRF token',
        redirectUrl: null,
      };
    }

    const params = new URLSearchParams();
    params.set('username', username);
    params.set('password', password);
    params.set('callbackUrl', callbackUrl);
    params.set('csrfToken', csrfToken);
    params.set('json', 'true');
    params.set('redirect', 'false');

    const signinResponse = await fetch(
      `${AUTHENTICATION_HOST}/api/auth/callback/credentials`,
      {
        method: 'POST',
        headers: {
          Accept: '*/*',
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        credentials: 'include',
        body: params.toString(),
      },
    );

    const signin = await signinResponse.json();

    if (!signinResponse.ok) {
      const url = new URL(signin.url);

      const errorSearchParams = new URLSearchParams(url.search);
      return {
        success: false,
        error: errorMessages.get(errorSearchParams.get('error')),
        redirectUrl: null,
      };
    }

    const { url } = signin;
    return { success: true, error: null, redirectUrl: url };
  } catch (exception) {
    console.error(
      '[signInWithCredentials] Error signing in',
      exception,
      callbackUrl,
    );
    return { success: false, error: exception, redirectUrl: null };
  }
};

export const signUpWithCredentials = async ({
  firstName,
  lastName,
  email,
  password,
  gender,
  phoneNumber,
  callbackUrl,
}: {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  gender?: string;
  phoneNumber?: string;
  country?: string;
  callbackUrl: string;
}) => {
  try {
    const body = {
      firstName,
      email,
      password,
      lastName,
      gender,
      phoneNumber,
    };

    const signupResponse = await fetch(
      `${AUTHENTICATION_HOST}/api/v1/auth/signup/`,
      {
        method: 'POST',
        headers: {
          Accept: '*/*',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      },
    );

    if (!signupResponse.ok) {
      const error = await signupResponse.json();
      return {
        success: false,
        error: errorMessages.get(error.error),
        redirectUrl: null,
      };
    }

    return await signInWithCredentials(email, password, callbackUrl);
  } catch (error) {
    console.error('Error signing up', error);
    return {
      success: false,
      error: error,
      redirectUrl: null,
    };
  }
};
