'use client';

import { useRef } from 'react';

import NavigationMenuList from 'common-toolbox/src/components/NavigationMenuList';
import Overlay from 'common-toolbox/src/components/dialogs/Overlay';
import useClickOutside from 'common-toolbox/src/hooks/useClickOutside';
import { RoleType } from 'common-toolbox/src/types/model/account';

import CSS from './NavbarMenu.module.scss';

interface NavbarMenuProps {
  onClose: () => void;
  roles: RoleType[];
}

function NavbarMenu({ onClose, roles }: NavbarMenuProps) {
  const ref = useRef(null);
  useClickOutside(ref, onClose);

  return (
    <Overlay portalId="menu-overlay" onEsc={onClose}>
      <dialog ref={ref} className={CSS.NavbarMenu}>
        <NavigationMenuList roles={roles} onLinkClick={onClose} />
      </dialog>
    </Overlay>
  );
}

export default NavbarMenu;
