export async function getNewMessagesCount() {
  const response = await fetch(
    `${process.env.NEXT_PUBLIC_MAIN_API_HOST}/api/v1/messages/new`,
    {
      credentials: 'include',
      cache: 'no-store',
    },
  );

  if (!response.ok) {
    return 0;
  }

  const payload = await response.json();
  return payload?.unread ?? 0;
}
