import classnames from 'classnames';

import CSS from './MenuItem.module.scss';
import MenuItemLink from './MenuItemLink';

interface MenuItemProps {
  text: string;
  url?: string;
  badgeNumber?: number;
  onClick: () => void;
  openModal?: boolean;
  isActive?: boolean;
  openInNewTab?: boolean;
}

function MenuItem({
  text,
  url,
  badgeNumber,
  onClick,
  openModal,
  isActive,
  openInNewTab = false,
}: MenuItemProps) {
  const classes = classnames(CSS.MenuItem, { [CSS.ActiveMenuItem]: isActive });
  return (
    <div className={classes}>
      <MenuItemLink
        url={url}
        onClick={onClick}
        className={CSS.Link}
        text={text}
        openModal={openModal}
        openInNewTab={openInNewTab}
      />
      {!!badgeNumber && badgeNumber > 0 && (
        <span className={CSS.MenuBadge}>{badgeNumber}</span>
      )}
    </div>
  );
}

export default MenuItem;
