type FetchResponseOK<T = any> = {
  ok: true;
  data: T;
  error?: undefined;
  status: number;
};

type FetchResponseError = {
  ok: false;
  data?: undefined;
  error: {
    message: string;
    code: string;
  };
  status: number;
};

export type FetchResponse<T = any> = FetchResponseOK<T> | FetchResponseError;

const readResponseBody = (response: Response) => {
  const contentType = response.headers.get('content-type');
  if (contentType && contentType.includes('application/json')) {
    return response.json();
  }
  return response.text();
};

export const query = async <T = any>(
  path: string,
  options?: RequestInit,
): Promise<FetchResponse<T>> => {
  try {
    const url = `${process.env.NEXT_PUBLIC_MAIN_API_HOST}${path}`;
    const response = await fetch(url, options);
    const payload = await readResponseBody(response);

    if (response.ok) {
      return { ok: true, data: payload as T, status: response.status };
    } else {
      return { ok: false, error: payload, status: response.status };
    }
  } catch (e) {
    return {
      ok: false,
      error: { message: 'Server error.', code: 'UNKNOWN' },
      status: 500,
    };
  }
};
