const errorMessages = {
  get(code: string | null) {
    if (!code) {
      return '';
    }

    switch (code) {
      case 'EmailCreateAccount':
        return 'Could not create this account. Please revisit your details or reset your password if your email is already registered.';
      case 'CredentialsSignin':
        return 'Invalid email or password. Please try again.';
      case 'NoCredentialsProvided':
        return 'No credentials provided. Please try again.';
      case 'NoEmailProvided':
        return 'No email address provided (or no permission given to share your email address). This is required to sign in. Please try again.';
      case 'AlreadyExists':
        return 'This email is already registered. Try signing in instead.';
      default:
        return 'An error occurred. Please try again.';
    }
  },
};

export default errorMessages;
