import { usePathname } from 'next/navigation';

import MenuItem from './MenuItem';
import useUnreadMessageCount from './hooks/useUnreadMessageCount';

interface ChatMenuItemProps {
  onLinkClick: () => void;
}

function ChatMenuItem({ onLinkClick }: ChatMenuItemProps) {
  const pathname = usePathname()!;
  const nrOfUnreadMessages = useUnreadMessageCount();

  return (
    <MenuItem
      text="Chat"
      url={`${process.env.NEXT_PUBLIC_USER_ENVIRONMENT_HOST}/messages/`}
      onClick={onLinkClick}
      isActive={pathname.startsWith('/messages/')}
      badgeNumber={nrOfUnreadMessages}
    />
  );
}

export default ChatMenuItem;
