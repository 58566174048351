"use client";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import Link from "next/link";
import React from "react";
import { sendClickEvent } from "../";
function TrackedLink(_a) {
    var children = _a.children, href = _a.href, trackingName = _a.trackingName, props = __rest(_a, ["children", "href", "trackingName"]);
    function onClick(event) {
        sendClickEvent({
            href: href.toString(),
            trackingName: trackingName,
            text: event === null || event === void 0 ? void 0 : event.currentTarget.innerText,
        });
    }
    return (React.createElement(Link, __assign({ href: href, onClick: onClick }, props), children));
}
export default TrackedLink;
