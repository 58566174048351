import { useQuery } from '@tanstack/react-query';

import { query } from '../../../helpers/utils/query';

const getNewMessagesCount = async () => {
  const response = await query(`/api/v1/messages/new`, {
    method: 'GET',
    credentials: 'include',
  });
  if (!response.ok) {
    return {
      unread: 0,
    };
  }

  return response.data;
};

function useUnreadMessageCount() {
  const { data: unreadMessages } = useQuery({
    queryKey: ['new-messages'],
    queryFn: getNewMessagesCount,
    refetchOnMount: false,
    refetchOnReconnect: true,
    refetchOnWindowFocus: true,
  });

  return unreadMessages?.unread || 0;
}

export default useUnreadMessageCount;
