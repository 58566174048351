const transformationsRegEx = /([a-z]+_[^,^\/]+,?)+/gi;

const imageExtensions = [
  'JPG',
  'PNG',
  'GIF',
  'BMP',
  'TIFF',
  'ICO',
  'PDF',
  'EPS',
  'PSD',
  'SVG',
  'WebP',
  'JXR',
  'WDP',
];

const videoExtensions = [
  'AVI',
  'FLV',
  'MOV',
  'MP4',
  'MPEG',
  'OGV',
  'WEBM',
  'WMV',
];

const DEFAULT_TRANFORMATIONS = [
  'c_fill,f_auto,fl_progressive,g_auto,q_auto,w_500/',
  'c_fill,f_auto,fl_progressive,g_auto,h_500,q_auto,r_0.0,w_500',
];

function stripDefaultTransformations(id: string) {
  let strippedString = id;
  DEFAULT_TRANFORMATIONS.forEach(
    (tranformation) =>
      (strippedString = strippedString.replace(tranformation, '')),
  );

  return strippedString;
}

const allExtensions = [...imageExtensions, ...videoExtensions];

function stripExtension(cloudinaryId: string) {
  const parts = cloudinaryId.split('.');
  const potentialExtension = parts[parts.length - 1];

  if (allExtensions.includes(potentialExtension.toUpperCase())) {
    return parts.slice(0, parts.length - 1).join('.');
  }

  return cloudinaryId;
}

function findCloudinaryId(src?: string, options = { stripExtension: false }) {
  const urlLastPartSplitOnUpload = src!.split('/upload/').pop() ?? '';
  const segments = urlLastPartSplitOnUpload.split('/');
  const idSegments = transformationsRegEx.test(segments[0])
    ? segments.slice(1)
    : segments;

  const id = stripDefaultTransformations(idSegments.join('/'));

  return options.stripExtension ? stripExtension(id) : id;
}

export default findCloudinaryId;
