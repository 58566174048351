'use client';

import { Suspense } from 'react';

import ExposedLinks from '@/common/components/Navbar/ExposedLinks';
import NavigationMenu from '@/common/components/NavigationMenu';
import { getAccountRoles } from '@/common/helpers/account';

import { useAccount } from '@/hooks/useAccount';

function NavbarClientComponent({ showSearch }: { showSearch?: boolean }) {
  const { account, status } = useAccount();

  const roles = getAccountRoles(account);

  return (
    status === 'ready' && (
      <Suspense>
        <NavigationMenu roles={roles} account={account} />
        <ExposedLinks roles={roles} showSearch={showSearch} />
      </Suspense>
    )
  );
}

export default NavbarClientComponent;
