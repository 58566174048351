import CSS from './MenuLabel.module.scss';

interface MenuLabelProps {
  children: string;
}

function MenuLabel({ children }: MenuLabelProps) {
  return <div className={CSS.MenuLabel}>{children}</div>;
}

export default MenuLabel;
