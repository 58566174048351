import IconType from '../../IconType';
import SVGIcon from '../../SVGIcon';
import CSS from './BadgeWithStarIcon.module.scss';

type BadgeWithStarIconProps = {
  variant?: 'default' | 'green';
} & IconType;

function BadgeWithStarIcon({ variant, ...props }: BadgeWithStarIconProps) {
  return (
    <SVGIcon {...props} size={props.size ?? 24} viewBox="0 0 24 24">
      <path
        d="M21.9999,11.9994946 C21.9999,17.5223126 17.5232234,21.9989892 12.0004054,21.9989892 C6.47758737,21.9989892 1.9999,17.5223126 1.9999,11.9994946 C1.9999,6.47667661 6.47758737,2 12.0004054,2 C17.5232234,2 21.9999,6.47667661 21.9999,11.9994946"
        data-variant={variant}
        className={CSS.Badge}
      />
      <path
        d="M17.971883,10.5112135 C17.8934673,10.2580645 17.6687536,10.0860215 17.4147803,10.0860215 L13.7046639,10.0860215 L12.5565174,6.3797235 C12.3985156,5.8734255 11.6014843,5.8734255 11.4434825,6.3797235 L10.2953361,10.0860215 L6.58521969,10.0860215 C6.33124642,10.0860215 6.10653274,10.2580645 6.02811703,10.5112135 C5.95087171,10.7643625 6.03630972,11.0420891 6.24112687,11.1981567 L9.24316115,13.4887865 L8.09618509,17.1950845 C8.01893976,17.4494624 8.10437777,17.7259601 8.30919493,17.8820276 C8.51518246,18.0380952 8.79139302,18.0380952 8.99738056,17.8820276 L11.9994148,15.5913978 L15.0026194,17.8820276 C15.1056132,17.9606759 15.2249923,18 15.3467123,18 C15.4672618,18 15.5878113,17.9606759 15.6908051,17.8820276 C15.8956222,17.7259601 15.9810602,17.4494624 15.9026445,17.1950845 L14.7568389,13.4887865 L17.7588731,11.1981567 C17.9636903,11.0420891 18.0491283,10.7643625 17.971883,10.5112135"
        data-variant={variant}
        className={CSS.Star}
      />
    </SVGIcon>
  );
}

export default BadgeWithStarIcon;
