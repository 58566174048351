'use client';

import { RefObject, useEffect, useRef } from 'react';

function useClickOutside(ref: RefObject<any>, handler: any) {
  const handlerRef = useRef(handler);

  useEffect(() => {
    function handleClickOutside(e: any) {
      if (
        ref.current &&
        !ref.current.contains(e?.target) &&
        document.body.contains(e?.target)
      ) {
        handlerRef.current(e);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('touchstart', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('touchstart', handleClickOutside);
    };
  });
}

export default useClickOutside;
