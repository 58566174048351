import { RoleType } from 'common-toolbox/src/types/model/account';

import BadgeWithStarIcon from '../icons/badges/full-color/BadgeWithStarIcon';
import SpyIcon from '../icons/people/full-color/SpyIcon';
import CSS from './RoleBadge.module.scss';

type RoleBadgeProps = {
  roles: RoleType[];
  className?: string;
};

function RoleBadge({ roles }: RoleBadgeProps) {
  if (roles.includes('impersonator')) {
    return (
      <figure className={CSS.Badge} title="Impersonator">
        <SpyIcon size={24} className={CSS.Icon} />
      </figure>
    );
  }

  if (roles.includes('admin')) {
    return (
      <figure className={CSS.Badge} title="Admin">
        <BadgeWithStarIcon variant="green" size={20} />
      </figure>
    );
  }

  return null;
}

export default RoleBadge;
