import CSS from './HamburgerCrossIcon.module.scss';

interface HamburgerCrossIconProps {
  isOpen: boolean;
  color?: 'primary' | 'white' | 'black';
}

function HamburgerCrossIcon({
  isOpen = false,
  color = 'black',
}: HamburgerCrossIconProps) {
  return (
    <div className={CSS.Icon} data-open={String(isOpen)}>
      <span className={CSS.Line} data-color={color} />
      <span className={CSS.Line} data-color={color} />
      <span className={CSS.Line} data-color={color} />
    </div>
  );
}

export default HamburgerCrossIcon;
