import clsx from 'clsx';
import NextLink from 'next/link';
import { ReactNode } from 'react';
import { UrlObject } from 'url';

import CSS from './Link.module.scss';

interface LinkProps {
  href: string | UrlObject;
  children: ReactNode;
  className?: string;
  size?: 'medium' | 'small' | 'extraSmall';
  variant?: 'default' | 'gray';
  [key: string]: any;
}

function Link({
  children,
  href,
  className,
  size = 'medium',
  variant = 'default',
  ...props
}: LinkProps) {
  const classes = clsx(CSS.Link, className, {
    [CSS.LinkSmall]: size === 'small',
    [CSS.LinkExtraSmall]: size === 'extraSmall',
    [CSS.LinkGray]: variant === 'gray',
  });

  return (
    <NextLink href={href} className={classes} {...props}>
      {children}
    </NextLink>
  );
}

export default Link;
