import { notFound } from 'next/navigation';

import {
  checkCandidateProgress,
  getAccountData,
} from 'common-toolbox/src/clients/AuthenticationClient';
import findCloudinaryId from 'common-toolbox/src/helpers/utils/findCloudinaryId';
import type { Account, RoleType } from 'common-toolbox/src/types/model/account';
import { User } from 'common-toolbox/src/types/next-auth';

export const getAccountDTO = async (
  accessToken: string,
  user: User,
  spy?: User,
): Promise<Account> => {
  const { success, data: accountData } = await getAccountData(accessToken);
  const userId = user.id ?? spy?.id;
  if (!success || !userId) {
    notFound();
  }

  const candidateProgress = await checkCandidateProgress(userId, accessToken);

  const { isCandidate } = candidateProgress;

  const account: Account = {
    id: accountData.id,
    realId: userId,
    email: user.email ?? '',
    firstName: user.firstName ?? '',
    photo: accountData.photo,
    isHost: user.roles?.includes('host'),
    isAdmin: spy?.roles?.includes('admin') || user.roles?.includes('admin'),
    isImpersonator: !!spy,
    prettyUrl: accountData.pretty_url,
    cloudinaryId: findCloudinaryId(accountData.photo),
    isCandidate,
    createdAt: accountData.created_at,
    featureFlags: accountData.feature_flags,
    address: {
      city: accountData.address.city,
      country: accountData.address.country,
      countryCode: accountData.countryCode,
    },
    hostPublicStatus: accountData.hostPublicStatus,
    hostAwayStatus: accountData.hostAwayStatus,
  };

  return account;
};

export function getAccountRoles(account: Account | null): RoleType[] {
  const roles: RoleType[] = [];

  if (!account) {
    roles.push('unlogged');
  } else {
    if (account.isHost) {
      roles.push('host');
    } else {
      roles.push('guest');
    }
    if (account.isAdmin) {
      roles.push('admin');
    }
    if (account.isCandidate) {
      roles.push('candidate');
    }
    if (account.isImpersonator) {
      roles.push('impersonator');
    }
    roles.push('loggedin');
  }
  return roles;
}
