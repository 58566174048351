'use client';

import TrackedLink from '@withlocals/analytics/TrackedLink';

interface MenuItemLinkProps {
  url?: string;
  text: string;
  openModal?: boolean;
  openInNewTab?: boolean;
  [key: string]: any;
}

function MenuItemLink({
  url,
  text,
  openModal,
  openInNewTab,
  ...rest
}: MenuItemLinkProps) {
  if (!url) {
    return (
      <button type="button" onClick={rest.onClick} {...rest}>
        {text}
      </button>
    );
  }

  return (
    <TrackedLink
      href={url}
      trackingName="navbar"
      rel="noopener noreferrer"
      target={openInNewTab ? '_blank' : undefined}
      {...rest}
    >
      {text}
    </TrackedLink>
  );
}

export default MenuItemLink;
