'use client';

import { usePathname } from 'next/navigation';
import { useMemo } from 'react';

import { RoleType } from 'common-toolbox/src/types/model/account';

import createNavbarLinks from './exposedNavbarLinks';

interface NavbarLinkProps {
  roles: RoleType[];
  exposed?: boolean;
}

function useExposedNavbarLinks({ roles }: NavbarLinkProps) {
  const pathname = usePathname();

  const linksToReturn = useMemo(() => {
    return createNavbarLinks(pathname)
      .filter((link) => {
        return link.roles.some((role) => roles.includes(role) || role === '*');
      })
      .sort((a, b) => b.priority - a.priority);
  }, [roles, pathname]);

  return linksToReturn;
}

export default useExposedNavbarLinks;
