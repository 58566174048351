import { Session } from 'common-toolbox/src/types/next-auth';

const _getSession = async (options: RequestInit): Promise<Session | null> => {
  if (!process.env.NEXT_PUBLIC_AUTHENTICATION_HOST) {
    throw new Error('NEXT_PUBLIC_AUTHENTICATION_HOST is not set');
  }
  const response = await fetch(
    `${process.env.NEXT_PUBLIC_AUTHENTICATION_HOST}/api/v1/session`,
    {
      ...options,
      method: 'GET',
    },
  );
  const jsonResponse = await response.json();

  if (Object.keys(jsonResponse).length === 0) {
    return null;
  }

  return jsonResponse as Session;
};
export const getSession = async () => {
  return _getSession({ credentials: 'include' });
};

export const getSessionViaHeader = async (sessionToken: string) => {
  return _getSession({
    headers: {
      Authorization: `Bearer ${sessionToken}`,
    },
  });
};
