'use client';

import { ChangeEvent } from 'react';

import CSS from './SelectMenuItem.module.scss';

interface SelectMenuItemProps {
  selectedItem?: string;
  options: Array<{ value: string; label: string }>;
  onSelect: (value: string) => void;
  label: string;
}

function SelectMenuItem({
  selectedItem,
  options,
  onSelect,
  label,
}: SelectMenuItemProps) {
  const onChange = (event: ChangeEvent<HTMLSelectElement>) => {
    onSelect(event.target.value);
  };

  return (
    <div className={CSS.SelectMenuItem}>
      <span className={CSS.Label}>{label}</span>
      <select className={CSS.Select} value={selectedItem} onChange={onChange}>
        {options.map(({ value, label }) => {
          return (
            <option key={value} value={value}>
              {label}
            </option>
          );
        })}
      </select>
    </div>
  );
}

export default SelectMenuItem;
